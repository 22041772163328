import request from '@/utils/request'

const categoryApi = {
    update: '/api/category',
    list: '/api/categories',
    get: '/api/category/',
    modifyproducts: '/api/modifyproducts',
    deletecategory: '/api/category/',
    removeproductfromcategory: '/api/removeproductfromcategory',
}

export function removeproductfromcategory(data) {
    return request({
        url: categoryApi.removeproductfromcategory,
        method: 'post',
        data: data
    });
}
export function modifyproducts (parameter) {
    return request({
        url: categoryApi.modifyproducts,
        method: 'post',
        data: parameter
    })
}

export function addorupdate (parameter) {
    return request({
        url: categoryApi.update,
        method: 'post',
        data: parameter
    })
}

export function list() {
    return request({
        url: categoryApi.list,
        method: 'get'
    });
}

export function getitem(id) {
    return request({
        url: categoryApi.get + id,
        method: 'get'
    });
}

export function deletecategory(id) {
    return request({
        url: categoryApi.deletecategory + id,
        method: 'delete'
    });
}